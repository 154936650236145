
$(function() {

	class Information {
		constructor() {
			this.defaults = {
				duration: 8000,
				title: null,        // string
				content: null,      // string or array
				type: null          // string 'success' or 'error' - default 'notice'
			};
			this.$li = $('<li />');
			this.$span = $('<span />').addClass('msg');
			this.container = 'oc-notify';

			this.refresh();
		}

		notice(options) { this.generate($.extend(options, { type: 'notice' })); }
		error(options) { this.generate($.extend(options, { type: 'error' })); }
		success(options) { this.generate($.extend(options, { type: 'success' })); }
		
		refresh() {
			if($('#'+this.container).length === 0)
				$('body').append($('<ul />').attr('id', this.container));

			this.$ = $('#'+this.container);
			this.events();
		}

		generate(options) {
			this.refresh();
			this.s = $.extend({}, this.defaults, options);

			var $item = this.$li.clone();
			if(this.s.title)
				$item.append($('<b class="notify-title" />').text(this.s.title));

			if(this.s.type) $item.addClass('notify-'+ this.s.type);
			this.$.append($item.append(this.parseContent()));

			setTimeout(() => {
				$item.addClass('notify-show');
			}, 50);

			this.destroy($item);
		}

		parseContent() {
			let content = [];
			if(this.s.content && this.s.content.length === 1) this.s.content = this.s.content[0];
			if(this.s.content && typeof this.s.content === 'object') {
				$.each(this.s.content, (k, v) => {
					content.push(this.$span.clone().html(v));
				});
			} else content.push(this.s.content);
			
			return content;
		}

		events() {
			this.$.off('click.oc').on('click.oc', 'li', (ev) => {
				this.destroy($(ev.currentTarget), 0);
			});
		}

		destroy($el, time=this.s.duration) {
			time !== false && time >= 0 && setTimeout(() => {
				$el.addClass('notify-hide');

				setTimeout(() => { $el.remove(); }, 500);
			}, time);
		}
	}

	window.information = new Information();

});